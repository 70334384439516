import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Performance = lazy(() => import('./screens/performance'));

const LittleRedRidingHood = lazy(() => import('./screens/performance/little_red_riding_hood'));
const Ratatuy = lazy(() => import('./screens/performance/ratatuy'));
const TheThreeLittlePigs = lazy(() => import('./screens/performance/the_three_little_pigs'));
const ThreeCarrots = lazy(() => import('./screens/performance/three_carrots'));
const PenguinGio = lazy(() => import('./screens/performance/penguin_gio'));

export function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path='/' element={<Performance />} />
          
          <Route exact path='/performance' element={<Performance />} />
          <Route exact path='/performance/little_red_riding_hood' element={<LittleRedRidingHood />} />
          <Route exact path='/performance/ratatuy' element={<Ratatuy />} />
          <Route exact path='/performance/the_three_little_pigs' element={<TheThreeLittlePigs />} />
          <Route exact path='/performance/three_carrots' element={<ThreeCarrots />} />
          <Route exact path='/performance/penguin_gio' element={<PenguinGio />} />
        </Routes>
      </Suspense>
    </Router>
  )
}
